import { gql } from '@apollo/client'

export const GET_WEB_HOOK = gql`
  query GetWebhook {
    getWebhook {
      webhookId
      kind
      url
      username
      password
      webhookHeader {
        webhookHeaderId
        value
        key
        isChecked
      }
    }
  }
`

export const PUSH_WEB_HOOK = gql`
  mutation PushUnreadRecord($id: String!, $webhookType: String!) {
    pushUnreadRecord(id: $id, webhookType: $webhookType) {
      statusCode
      message
    }
  }
`

export const SAVE_URL_WEB_HOOK = gql`
  mutation UpdateWebhook($data: UpdateWebhookInput!, $webhookId: String!) {
    updateWebhook(data: $data, webhookId: $webhookId) {
      webhookId
      url
      updatedAt
      createdAt
    }
  }
`

export const PUSH_READ_RECORDS = gql`
  mutation UpdateLastRecordForParty($lastRecordRead: Int!) {
    updateLastRecordForParty(data: { lastRecordRead: $lastRecordRead }) {
      partyId
      lastRecordRead
    }
  }
`

export const GET_LAST_READ_RECORD = gql`
  query LastRecordForCarrier {
    lastRecordForCarrier {
      lastRecord
      lastRecordConfirmed
      unreadRecord
    }
  }
`

export const GET_ACTIVITIES_LOG = gql`
  query GetActivitiesLog($skip: Float!, $take: Float!) {
    activities(data: { skip: $skip, take: $take }) {
      activityId
      activityType
      createdAt
      jsonPacket
      userName
      statusResponse
    }
  }
`

export const FILTER_BY_MULTI_COLUMN = gql`
  query FilterByMultiColumn($data: SearchInput!) {
    filterByMultiColumn(data: $data) {
      activityId
      activityType
      createdAt
      jsonPacket
      userName
      statusResponse
    }
  }
`

export const FILTER_BY_GROUP = gql`
  query FilterByGroup($data: FilterInput!) {
    filterByGroup(data: $data) {
      key
    }
  }
`

export const GET_API_KEY = gql`
  query GetApiKeys {
    apiKeys {
      apiKeyId
      createdAt
      name
      accessKey
      status
    }
  }
`

export const CHANGE_STATUS_API_KEY = gql`
  mutation ChangeStatusApiKey($apiKeyId: String!, $status: Boolean!) {
    updateActiveStatusAndName(data: { apiKeyId: $apiKeyId, status: $status }) {
      status
      name
    }
  }
`

export const GENERATE_KEY = gql`
  mutation GenerateKey($name: String!) {
    addApiKey(data: { name: $name }) {
      apiKeyId
      createdAt
      name
      accessKey
      status
    }
  }
`

export const DELETE_KEY = gql`
  mutation DeleteKey($apiKeyId: String!) {
    deleteApiKey(data: { apiKeyId: $apiKeyId }) {
      name
    }
  }
`

export const GET_DUMMY_DATASET = gql`
  query GetAllBondData {
    getAllBondData {
      data {
        bondAmount
        bondAmountChangesPositiveAndNegative
        bondDefinitionState
        bondDescription
        bondReferenceNumber
        bondRiskState
        cancellableIndicator
        cancelOrCloseDate
        cancelOrCloseReason
        carrierBondNumber
        carrierBranch
        carrierCommissionPercent
        carrierTransactionCommissionAmount
        carrierUnderwriter
        classCode
        dateChangedFlagTransaction
        effectiveDate
        expiryDate
        noticeInDays
        obligeeAddressLine1
        obligeeAddressLine2
        obligeeCity
        obligeeKey
        obligeeName
        obligeeState
        obligeeZipcode
        principalAccountAddressLine1
        principalAccountAddressLine2
        principalAccountCity
        principalAccountKey
        principalAccountName
        principalAccountState
        principalAccountZipcode
        principalAddressLine1
        principalAddressLine2
        principalCity
        principalKey
        principalName
        principalState
        principalZipcode
        probateCourtState
        probateTypeOfBond
        rate
        renewAction
        bondPurchaseDate
        transactionComment
        transactionPremium
        transactionType
      }
      fileFormat
      fileName
    }
  }
`
export const UPDATE_WEBHOOK_PASSWORD = gql`
  mutation UpdateWebhookBasicAuthentication(
    $data: UpdateWebhookBasicAuthenticationInput!
    $webhookId: String!
  ) {
    updateWebhookBasicAuthentication(data: $data, webhookId: $webhookId) {
      webhookId
      username
      password
    }
  }
`
export const REQUEST_ACC_TO_PRODUCTION = gql`
  mutation Mutation($data: RequestAccountProductionInput!) {
    requestAccountToProduction(data: $data) {
      firstName
      lastName
      phoneNumber
      email
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorPhoneNumber
      businessSponsorEmail
      partyId
      kind
      isProduction
      userId
    }
  }
`
export const GET_STATUS_REQUEST_ACC = gql`
  query Query {
    getStatusRequestAccount
  }
`
export const CREATE_PRODUCTION_ACC = gql`
  mutation CreateProductionAccount($data: ProductionAccountInput!) {
    createProductionAccount(data: $data) {
      isProduction
    }
  }
`

export const CHANGE_KEY_NAME = gql`
  mutation ChangeKeyName($data: ChangeApiKeyInput!) {
    changeKeyName(data: $data) {
      apiKeyId
      createdAt
      name
      accessKey
      status
    }
  }
`
export const GET_CLIENT_CREDENTIAL_DEV = gql`
  query GetClientCredential {
    getClientCredential {
      audience
      clientId
      secretKey
      Auth0Url
    }
  }
`

export const CREATE_CLIENT_CREDENTIAL_DEV = gql`
  mutation CreateClientCredential($data: CreateClientCredential!) {
    createClientCredential(data: $data) {
      audience
      clientId
      secretKey
      Auth0Url
    }
  }
`

export const DELETE_CLIENT_CREDENTIAL_DEV = gql`
  mutation DeleteClientCredential {
    deleteClientCredential {
      audience
      Auth0Url
      clientId
      secretKey
    }
  }
`

export const GET_AGENCIES_BY_AMS = gql`
  query GetAgenciesByAMS($skip: Int, $take: Int) {
    getAgenciesByAMS(skip: $skip, take: $take) {
      agencyAmsId
      agencyAmsKey
      agency {
        agencyId
        agencyKey
        agencyName
      }
    }
  }
`

export const UPDATE_AGENCY_KEY = gql`
  mutation UpdateAgencyKey($data: UpdateAgencyKeyInput!) {
    updateAgencyKey(data: $data) {
      agencyAmsId
      agencyAmsKey
    }
  }
`

export const GET_AGENT_BY_AMS = gql`
  query GetAgentByAMS($agencyId: String!) {
    getAgentByAMS(agencyId: $agencyId) {
      agencyAgentId
      agencyAgentKey
      agent {
        agentId
        agentName
        agentEmail
      }
    }
  }
`

export const UPDATE_AGENT_KEY = gql`
  mutation Mutation($data: UpdateAgentKeyInput!) {
    updateAgentKey(data: $data) {
      agencyAgentId
      agencyAgentKey
    }
  }
`
export const GET_PRINCIPAL_ACCOUNT_FOR_MANAGER = gql`
  query GetPrincipalAccountForManager($page: Int, $limit: Int) {
    getPrincipalAccountForManager(page: $page, limit: $limit) {
      items {
        principalAccountId
        principalAccountName
        principalAccountBusinessEmail
        principalAccountContactName
        principalAccountPhoneNumber
        principalAccountAddressLine1
        principalAccountAddressLine2
        principalAccountCity
        principalAccountState
        principalAccountZipcode
      }
      pageInfo {
        currentPage
        totalPages
        totalItems
        itemsPerPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const UPDATE_PRINCIPAL_ACCOUNT_FOR_MANAGER = gql`
  mutation UpdatePrincipalAccountForManager($data: UpdatePrincipalAccountInput!, $principalAccountId: String!) {
    updatePrincipalAccountForManager(data: $data, principalAccountId: $principalAccountId) {
      principalAccountId
      principalAccountName
      principalAccountBusinessEmail
      principalAccountContactName
      principalAccountPhoneNumber
      principalAccountAddressLine1
      principalAccountAddressLine2
      principalAccountCity
      principalAccountState
      principalAccountZipcode
    }
  }
`
