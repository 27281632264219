import { useMutation } from '@apollo/client'
import React from 'react'
import { googleLogout, GoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { SAVE_USER_INFO } from '../../queries/login'
import { login, setUserInfo } from '../../redux/actions/users'
import { LocalStorageKey, saveDataToLocalStore } from '../../helpers/localStorage'
import { notify } from '../toastify'
import { MESSAGES, STATUS } from '../../constants/messages'

interface Props {}

const ButtonSignIn: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [saveUserInfo] = useMutation(SAVE_USER_INFO)

  const onLoginSuccess = async (res: any) => {
    try {
      const dataRes = (await saveUserInfo({ variables: { tokenId: res.credential } })) as any
      if (dataRes && dataRes.data && dataRes.data.login) {
        const { accessToken, user } = dataRes.data.login
        dispatch(login())
        saveDataToLocalStore(LocalStorageKey.ACCESS_TOKEN, accessToken)
        dispatch(setUserInfo(user))
        // refreshTokenSetup(res)
        navigate('/', { replace: true })
        window.location.reload()
        saveDataToLocalStore(
          LocalStorageKey.USER_INFO,
          JSON?.stringify({
            googleId: user?.gid,
            imageUrl: user?.avatar,
            email: user?.email,
            name: user?.firstName,
          }),
        )
        saveDataToLocalStore(LocalStorageKey.ACCESS_TOKEN_GG, res.credential)
      }
    } catch (error: any) {
      notify(error?.message || MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)
      googleLogout()
    }
  }

  return (
    <GoogleLogin
      onSuccess={onLoginSuccess}
      onError={() => notify(MESSAGES.ERROR.E_SOMETHING_WENT_WRONG, STATUS.ERROR)}
      hosted_domain="getpropellerbonds.com"
      shape="circle"
      text="signin"
      locale="en"
    />
  )
}

export default ButtonSignIn
